// src/components/TaskList.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { Container, List, ListItem, Checkbox, Typography, FormControlLabel, Switch } from '@mui/material';
import { SwipeableList, SwipeableListItem, LeadingActions, TrailingActions, SwipeAction } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import dayjs from 'dayjs';

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [showCompleted, setShowCompleted] = useState(false); // State to control the visibility of completed tasks

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'tasks'), (snapshot) => {
      const taskList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTasks(taskList);
    });

    return () => unsubscribe();
  }, []);

  const toggleTask = async (task) => {
    await updateDoc(doc(db, 'tasks', task.id), {
      completed: !task.completed,
    });
  };

  const deleteTask = async (taskId) => {
    await deleteDoc(doc(db, 'tasks', taskId));
  };

  const archiveTask = async (task) => {
    await updateDoc(doc(db, 'tasks', task.id), {
      archived: true, // Assuming you have an 'archived' field in your Firestore schema
    });
  };

  const leadingActions = (task) => (
    <LeadingActions>
      <SwipeAction onClick={() => archiveTask(task)}>
        <div style={{ padding: '16px', backgroundColor: 'orange', color: 'white' }}>Archive</div>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (task) => (
    <TrailingActions>
      <SwipeAction destructive={true} onClick={() => deleteTask(task.id)}>
        <div style={{ padding: '16px', backgroundColor: 'red', color: 'white' }}>Delete</div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <Container className="container" maxWidth="sm">
      <Typography variant="h5" gutterBottom>Task List</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={showCompleted}
            onChange={() => setShowCompleted((prev) => !prev)}
            color="primary"
          />
        }
        label="Show Completed Tasks"
        style={{ margin: '10px 0' }}
      />
      <SwipeableList>
        {tasks
          .filter((task) => (showCompleted ? true : !task.completed)) // Filter tasks based on the toggle state
          .map((task) => (
            <SwipeableListItem
              key={task.id}
              leadingActions={leadingActions(task)}
              trailingActions={trailingActions(task)}
            >
              <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Checkbox 
                  checked={task.completed}
                  onChange={() => toggleTask(task)}
                />
                <div>
                  <Typography>{task.name}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Assigned to: {task.assignedTo} | Due: {dayjs(task.dueDate).format('MMM DD, YYYY')}
                  </Typography>
                </div>
              </ListItem>
            </SwipeableListItem>
          ))}
      </SwipeableList>
      {tasks.length === 0 && <Typography>No tasks to show!</Typography>}
    </Container>
  );
};

export default TaskList;
