// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAOsU-4yu_lMXLcbcKNCioajyKln-MlgVI",
    authDomain: "cat-tracker-59882.firebaseapp.com",
    projectId: "cat-tracker-59882",
    storageBucket: "cat-tracker-59882.appspot.com",
    messagingSenderId: "644547945784",
    appId: "1:644547945784:web:0c5f6854c3c3467d6dcdcd",
    measurementId: "G-Z1Z27B4DK9"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
