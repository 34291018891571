// src/components/CalendarView.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Container, Typography } from '@mui/material';

const CalendarView = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const startOfDay = new Date(selectedDate);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(selectedDate);
    endOfDay.setHours(23, 59, 59, 999);

    const tasksQuery = query(
      collection(db, 'tasks'),
      where('dueDate', '>=', startOfDay.toISOString()),
      where('dueDate', '<=', endOfDay.toISOString())
    );

    const unsubscribe = onSnapshot(tasksQuery, (snapshot) => {
      const tasksForDate = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksForDate);
    });

    return () => unsubscribe();
  }, [selectedDate]);

  return (
    <Container className="container" maxWidth="sm">
      <Typography variant="h5" gutterBottom>Calendar View</Typography>
      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileContent={({ date }) => {
          const dayTasks = tasks.filter(task => new Date(task.dueDate).toDateString() === date.toDateString());
          return dayTasks.length > 0 ? <span>📌</span> : null; // Mark days with tasks
        }}
      />
      <Typography variant="h6" gutterBottom>Tasks for {selectedDate.toDateString()}</Typography>
      {tasks.map(task => (
        <Typography key={task.id}>{task.name} - Assigned to: {task.assignedTo}</Typography>
      ))}
    </Container>
  );
};

export default CalendarView;
