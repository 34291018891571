 // src/components/Login.js
import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Alert } from '@mui/material';

const allowedEmails = [
    'catnamedbeetle@gmail.com',
    'alex.d.vela@gmail.com'
    // Add more allowed emails as needed
  ];

const Login = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in and redirect to tasks
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/tasks'); // Redirect to tasks if logged in
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const userEmail = result.user.email;

      // Check if the email is in the allowed list
      if (!allowedEmails.includes(userEmail)) {
        await auth.signOut();
        setError('Access denied: Your email is not authorized to access this app.');
      } else {
        navigate('/tasks'); // Redirect to tasks if sign-in is successful
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container className="container" maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleGoogleSignIn} 
        fullWidth
        style={{ marginTop: '20px' }}
      >
        Sign in with Google
      </Button>
    </Container>
  );
};

export default Login;
