// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import TaskList from './components/TaskList';
import CreateTask from './components/CreateTask';
import Events from './components/Events';
import DueToday from './components/DueToday';
import CalendarView from './components/CalendarView';
import Login from './components/Login';
import Navbar from './components/Navbar';
import FloatingActionButton from './components/FloatingActionButton'; // Import FAB

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <div>Loading...</div>;
    }
    return user ? children : <Navigate to="/login" />;
  };

  return (
    <div className="App">
      {user && <Navbar />}
      <Routes>
        <Route path="/login" element={user ? <Navigate to="/tasks" /> : <Login />} />
        <Route path="/tasks" element={<ProtectedRoute><TaskList /></ProtectedRoute>} />
        <Route path="/create-task" element={<ProtectedRoute><CreateTask /></ProtectedRoute>} />
        <Route path="/events" element={<ProtectedRoute><Events /></ProtectedRoute>} />
        <Route path="/due-today" element={<ProtectedRoute><DueToday /></ProtectedRoute>} />
        <Route path="/calendar" element={<ProtectedRoute><CalendarView /></ProtectedRoute>} />
        <Route path="*" element={<Navigate to="/tasks" />} />
      </Routes>
      {user && <FloatingActionButton />} {/* Render the FAB */}
    </div>
  );
}

export default App;
