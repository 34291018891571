// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Button, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = () => {
    auth.signOut();
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" component="div">
            Cat Tracker
          </Typography>
          <IconButton 
            edge="end" 
            color="inherit" 
            aria-label="menu" 
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <List style={{ width: 250 }}>
          <ListItem button component={Link} to="/tasks" onClick={toggleDrawer(false)}>
            <ListItemText primary="Tasks" />
          </ListItem>
          <ListItem button component={Link} to="/due-today" onClick={toggleDrawer(false)}>
            <ListItemText primary="Due Today" />
          </ListItem>
          <ListItem button onClick={() => { handleLogout(); toggleDrawer(false)(); }}>
            <ExitToAppIcon />
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
