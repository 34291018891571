// src/components/FloatingActionButton.js
import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const FloatingActionButton = () => {
  const navigate = useNavigate();

  return (
    <Fab 
      color="primary" 
      aria-label="add" 
      onClick={() => navigate('/create-task')}
      style={{ position: 'fixed', bottom: 16, right: 16 }}
    >
      <AddIcon />
    </Fab>
  );
};

export default FloatingActionButton;
