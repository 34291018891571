// src/components/DueToday.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, query, where, updateDoc, doc } from 'firebase/firestore';
import { Container, List, ListItem, Checkbox, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';

const DueToday = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // Get the start and end of the current day in UTC
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Start of the next day

    // Convert JavaScript Date objects to Firestore Timestamps
    const todayTimestamp = Timestamp.fromDate(today);
    const tomorrowTimestamp = Timestamp.fromDate(tomorrow);

    // Query Firestore for tasks due today and not completed
    const tasksQuery = query(
      collection(db, 'tasks'),
      where('completed', '==', false),
      where('dueDate', '>=', todayTimestamp), // Use dueDate or the field representing the due date
      where('dueDate', '<', tomorrowTimestamp)
    );

    const unsubscribe = onSnapshot(tasksQuery, (snapshot) => {
      const dueTodayTasks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTasks(dueTodayTasks);
    });

    return () => unsubscribe();
  }, []);

  const toggleTask = async (task) => {
    await updateDoc(doc(db, 'tasks', task.id), {
      completed: true,
    });
  };

  return (
    <Container className="container" maxWidth="sm">
      <Typography variant="h5" gutterBottom>Tasks Due Today</Typography>
      <List>
        {tasks.map(task => (
          <ListItem key={task.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Checkbox 
              checked={task.completed}
              onChange={() => toggleTask(task)}
            />
            <div>
              <Typography>{task.name}</Typography>
              <Typography variant="caption" color="textSecondary">Assigned to: {task.assignedTo}</Typography>
            </div>
          </ListItem>
        ))}
      </List>
      {tasks.length === 0 && <Typography>No tasks due today!</Typography>}
    </Container>
  );
};

export default DueToday;
