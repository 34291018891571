// src/components/CreateTask.js
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { Container, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const allowedUsers = [
  'alex.d.vela@gmail.com',
  'catnamedbeetle@gmail.com'
  // Add other authorized users here
];

const CreateTask = () => {
  const [newTask, setNewTask] = useState('');
  const [repeatInterval, setRepeatInterval] = useState('none');
  const [assignedTo, setAssignedTo] = useState('');
  const [dueDate, setDueDate] = useState(dayjs()); // Initialize with today's date

  const addTask = async () => {
    if (newTask.trim() !== '' && assignedTo.trim() !== '') {
      await addDoc(collection(db, 'tasks'), {
        name: newTask,
        completed: false,
        timestamp: serverTimestamp(),
        repeatInterval: repeatInterval,
        assignedTo: assignedTo,
        dueDate: dueDate.toISOString(),
      });
      setNewTask('');
      setRepeatInterval('none');
      setAssignedTo('');
      setDueDate(dayjs()); // Reset due date to today
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container className="container" maxWidth="sm">
        <TextField 
          fullWidth
          variant="outlined"
          placeholder="Add a new task"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <InputLabel>Repeat Interval</InputLabel>
          <Select
            value={repeatInterval}
            onChange={(e) => setRepeatInterval(e.target.value)}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <InputLabel>Assign To</InputLabel>
          <Select
            value={assignedTo}
            onChange={(e) => setAssignedTo(e.target.value)}
          >
            {allowedUsers.map((user) => (
              <MenuItem key={user} value={user}>{user}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <DatePicker
          label="Due Date"
          value={dueDate}
          onChange={(newValue) => setDueDate(newValue)}
          renderInput={(params) => <TextField {...params} fullWidth style={{ marginBottom: '10px' }} />}
        />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={addTask} 
          fullWidth
        >
          Add Task
        </Button>
      </Container>
    </LocalizationProvider>
  );
};

export default CreateTask;
