// src/components/Events.js
import React, { useState } from 'react';
import { db, storage } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Container, TextField, Button, FormControlLabel, Checkbox, Typography } from '@mui/material';

const Events = () => {
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [alertOthers, setAlertOthers] = useState(false); // State to handle alert option

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const addEvent = async () => {
    if (description.trim() === '') return;

    let imageUrl = '';

    // If an image is selected, upload it to Firebase Storage
    if (image) {
      const imageRef = ref(storage, `events/${Date.now()}_${image.name}`);
      await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(imageRef);
    }

    // Save event data in Firestore
    await addDoc(collection(db, 'events'), {
      description,
      imageUrl,
      timestamp: new Date(),
      alertOthers, // Save the alert option
    });

    // Reset form fields
    setDescription('');
    setImage(null);
    setAlertOthers(false);
  };

  return (
    <Container className="container" maxWidth="sm">
      <Typography variant="h4" gutterBottom>Log Interesting Event</Typography>
      <TextField 
        fullWidth
        variant="outlined"
        value={description} 
        onChange={(e) => setDescription(e.target.value)} 
        placeholder="Describe the event" 
        style={{ marginBottom: '10px' }}
      />
      <input 
        type="file" 
        onChange={handleFileChange} 
        style={{ marginBottom: '10px' }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={alertOthers}
            onChange={(e) => setAlertOthers(e.target.checked)}
            color="primary"
          />
        }
        label="Alert the other person"
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={addEvent}
        fullWidth
        style={{ marginTop: '10px' }}
      >
        Add Event
      </Button>
    </Container>
  );
};

export default Events;
